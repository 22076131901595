<template>
  <div class="container">
    <div class="tabs-nav" @tab-click="handleClick">
      <el-tabs v-model="activeName" >
        <el-tab-pane label="艺术比赛" name="0">
          <Race ref="race" />
        </el-tab-pane>
				<el-tab-pane label="艺术展演" name="1">
				  <Activity ref="activity" />
				</el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Race from './component/artRace'
import Activity from './component/artActivity'
export default {
  data() {
    return {
      activeName: '',
    }
  },
  components: {
    Race,
		Activity
  },
	watch: {
		'activeName'(nv, ov) {
			if (nv != ov) {
				this.handleClick()
			}
		}
	},
	created() {
		this.activeName = '0'
	},
  methods: {
		handleClick(tab) {
			this.$nextTick(() => {
				switch(this.activeName) {
					case '0': this.$refs.race.getFirst(); break;
					case '1': this.$refs.activity.getFirst(); break;
				}
			})
		}
  }
}
</script>

<style lang="scss" scoped>
.container {
  .tabs-nav {
    width: 100%;
    padding: 15px 16px 30px;
    background: #fff;
    border-radius: 4px 4px 0px 0px;
    box-sizing: border-box;
  }
}
</style>